// @flow

import { applyStylingHoc, type StyleOuterProps, type StyleProps } from '@fcse/tsbob/dist/lib/styling'
import classNames from 'classnames'
import { Link, useIntl } from 'gatsby-plugin-intl'
import React from 'react'
import Button from '../../../../components/basic/Button'
import OffCanvas from '../../../../components/basic/OffCanvas'
import messages from './../../Header.lang'
import ContactLinks from './../ContactLinks'
import Language from './../Language'
import Subnav from './../Subnav'
import baseStyles from './Mainnav.scss'

type Props = {
  ...StyleProps,
  customNav?: any,
  hasCustomNav?: boolean,
  contactBtn?: any
}

const Mainnav = ({ styles, customNav, hasCustomNav, contactBtn }: Props) => {
  const { formatMessage, locale } = useIntl()
  const MenuMobile = ({ children }: Object) => (
    <>
      <OffCanvas
        styles={styles.offcanvas}
        isInitiallyOpen={true}
        customBurgerIcon={classNames(styles.toggleIcon, 'icon-menu')}
        customCrossIcon="icon-close"
        side="right"
        title=""
        theme="dark"
        triggerProps={{
          color: 'link',
          tag: 'span'
        }}
      >
        {children}
      </OffCanvas>
    </>
  )
  const ChildNodesComponent = ({ contactLinksPlainView }: Object) => (
    <>
      <Language styles={styles.languageMobile} />
      {hasCustomNav ? (
        customNav
      ) : (
        <ul className={styles.navList}>
          <li className={styles.navItem}>
            <Subnav
              parentLink={
                <Link to="/solutions" className={styles.navLink} activeClassName={styles['is-active']} partiallyActive>
                  {formatMessage(messages.Solutions)}
                </Link>
              }
              styles={styles.subnav}
            >
              <Link
                to="/solutions/it-system-modernization/"
                className={styles.subLink}
                activeClassName={styles['is-active']}
              >
                {formatMessage(messages.SystemModernization)}
              </Link>
              <Link to="/case-study/" className={styles.subLink} activeClassName={styles['is-active']}>
                {formatMessage(messages.CaseStudies)}
              </Link>
              <Subnav
                parentLink={
                  <Link to="/products/" className={styles.subLink} activeClassName={styles['is-active']}>
                    {formatMessage(messages.Products)}
                  </Link>
                }
              >
                {/* No product nav items at the moment*/}
                <Link to="/products/quickstart/" className={styles.subLink} activeClassName={styles['is-active']}>
                  {formatMessage(messages.quickstart)}
                </Link>
                <Link to="/products/fusion/" className={styles.subLink} activeClassName={styles['is-active']}>
                  {formatMessage(messages.fusion)}
                </Link>
                <Link to="/products/travelsandbox/" className={styles.subLink} activeClassName={styles['is-active']}>
                  {formatMessage(messages.travelsandbox)}
                </Link>
                <Link to="/products/freshms/" className={styles.subLink} activeClassName={styles['is-active']}>
                  {formatMessage(messages.freshms)}
                </Link>
                <Link to="/products/mightyc/" className={styles.subLink} activeClassName={styles['is-active']}>
                  {formatMessage(messages.mightyc)}
                </Link>
              </Subnav>
            </Subnav>
          </li>
          <li className={styles.navItem}>
            <Subnav
              parentLink={
                <Link to="/services/" className={styles.navLink} activeClassName={styles['is-active']} partiallyActive>
                  {formatMessage(messages.Services)}
                </Link>
              }
              styles={styles.subnav}
            >
              <Link to="/services/it-consulting/" className={styles.subLink} activeClassName={styles['is-active']}>
                {formatMessage(messages.itconsulting)}
              </Link>
              <Link to="/services/concept-strategy/" className={styles.subLink} activeClassName={styles['is-active']}>
                {formatMessage(messages.conceptstrategy)}
              </Link>
              <Link to="/services/ui-ux-design/" className={styles.subLink} activeClassName={styles['is-active']}>
                {formatMessage(messages.uiuxdesign)}
              </Link>
              <Link to="/services/development/" className={styles.subLink} activeClassName={styles['is-active']}>
                {formatMessage(messages.development)}
              </Link>
              <Link to="/services/system-integration/" className={styles.subLink} activeClassName={styles['is-active']}>
                {formatMessage(messages.systemintegration)}
              </Link>
              <Link to="/services/system-engineering/" className={styles.subLink} activeClassName={styles['is-active']}>
                {formatMessage(messages.systemengineering)}
              </Link>
              <Link to="/services/app-development/" className={styles.subLink} activeClassName={styles['is-active']}>
                {formatMessage(messages.appdevelopment)}
              </Link>
              <Link
                to="/services/headless-cms-integration/"
                className={styles.subLink}
                activeClassName={styles['is-active']}
              >
                {formatMessage(messages.hcmsintegration)}
              </Link>
            </Subnav>
          </li>
          <li className={styles.navItem}>
            <Subnav
              parentLink={
                <Link
                  to="/references/"
                  className={styles.navLink}
                  activeClassName={styles['is-active']}
                  partiallyActive
                >
                  {formatMessage(messages.References)}
                </Link>
              }
              styles={styles.subnav}
            >
              <Link to="/case-study/" className={styles.subLink} activeClassName={styles['is-active']}>
                {formatMessage(messages.CaseStudies)}
              </Link>
              <Link to="/references/" className={styles.subLink} activeClassName={styles['is-active']}>
                {formatMessage(messages.allReferences)}
              </Link>
            </Subnav>
          </li>
          <li className={styles.navItem}>
            <Subnav
              parentLink={
                <Link to="/about-us/" className={styles.navLink} activeClassName={styles['is-active']} partiallyActive>
                  {formatMessage(messages.AboutUs)}
                </Link>
              }
              styles={styles.subnav}
            >
              <a
                href={formatMessage(messages.blogPath)}
                target="_blank"
                rel="noopener noreferrer"
                className={styles.subLink}
              >
                {formatMessage(messages.Blog)}
              </a>
              <Link to="/partner/" className={styles.subLink} activeClassName={styles['is-active']}>
                {formatMessage(messages.Partner)}
              </Link>
              <Link to="/about-us/technologies/" className={styles.subLink} activeClassName={styles['is-active']}>
                {formatMessage(messages.technologies)}
              </Link>
              <Link to="/about-us/why-agile/" className={styles.subLink} activeClassName={styles['is-active']}>
                {formatMessage(messages.whyAgile)}
              </Link>
              <Link to="/newsroom/" className={styles.subLink} activeClassName={styles['is-active']}>
                {formatMessage(messages.newsroom)}
              </Link>
            </Subnav>
          </li>
          <li className={styles.navItem}>
            <Subnav
              parentLink={
                <Link
                  to='/career/'
                  className={styles.navLink}
                  activeClassName={styles['is-active']}
                  partiallyActive
                >
                  {formatMessage(messages.Career)}
                </Link>
              }
              styles={styles.subnav}
            >
              { locale === 'en' && (
                <Link
                  to='/career/vacancies/frontend-developer-react/'
                  className={styles.subLink}
                  activeClassName={styles['is-active']}
                >
                  {formatMessage(messages.frontendReactTitle)}
                </Link>
              )}
              { locale === 'en' && (
                <Link
                  to='/career/vacancies/senior-full-stack-developer-php/'
                  className={styles.subLink}
                  activeClassName={styles['is-active']}
                >
                  {formatMessage(messages.seniorPHPFullStackTitle)}
                </Link>
              )}
              { locale === 'de' && (
                <Link
                  to='/career/vacancies/project-manager/'
                  className={styles.subLink}
                  activeClassName={styles['is-active']}
                >
                  {formatMessage(messages.seniorprojectmanager)}
                </Link>
              )}
              <Link
                to='/career/vacancies/technical-project-manager/'
                className={styles.subLink}
                activeClassName={styles['is-active']}
              >
                {formatMessage(messages.technicalprojectmanager)}
              </Link>
              <Link
                to='/career/vacancies/senior-software-architect/'
                className={styles.subLink}
                activeClassName={styles['is-active']}
              >
                {formatMessage(messages.seniorSoftwareArchitect)}
              </Link>
            </Subnav>
          </li>
        </ul>
      )}
      <Language styles={styles.languageDesktop} />
      <ContactLinks plainView={contactLinksPlainView} />
      {contactBtn ? (
        contactBtn
      ) : (
        <Link to="/contact/">
          <Button styles={styles.contactBtn} tag="span" color="accent" rounded>
            {formatMessage(messages.Contact)}
          </Button>
        </Link>
      )}
    </>
  )

  return (
    <nav className={styles.root}>
      <div className={styles.menuDesktop}>
        <ChildNodesComponent />
      </div>
      <div className={styles.menuMobile}>
        <Language styles={styles.languageMobileMenu} />
        <ContactLinks styles={styles.menuMobileContactLinks} />
        <Link to="/contact/" alt="contact" aria-label="contact">
          <Button styles={styles.menuMobileContactBtn} tag="span" color="accent" rounded>
            <span className={classNames(styles.menuMobileContactBtnIcon, 'icon-speechbubble')} />
            <span className={styles.menuMobileContactBtnText}>{formatMessage(messages.Contact)}</span>
          </Button>
        </Link>
        <MenuMobile>
          <ChildNodesComponent contactLinksPlainView={true} />
        </MenuMobile>
      </div>
    </nav>
  )
}

export default applyStylingHoc(baseStyles)<$Diff<Props, StyleProps> & StyleOuterProps, Function>(Mainnav)
